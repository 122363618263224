import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import Nav from '../components/nav'
import Footer from '../components/footer'
import Img from "gatsby-image"

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <Nav />
            <div className="layout">
				<div className="layout__wide padding__large">
					<div className="flex flex__space-between">
						<div className="col__80 body">
							<h1>{ entry.frontmatter.heading }</h1>
						</div>
					</div>
				</div>
            </div>
            <div className="layout padding__large--bottom">
                <div className="layout__wide body">
                    <div className="images">
                    {entry.frontmatter.images.map( (p, i) => (
                        <div className="image" key={i}>
                            <figure>
                                <Img fluid={p.image.childImageSharp.fluid} alt={p.heading} />
                            </figure>
                            <h3>{p.heading}</h3>
                            <p>{p.text}</p>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
                heading
                sub_heading
                images {
                    image {
                        childImageSharp {
                            fluid(maxWidth: 800, maxHeight: 450) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    heading
                    text
                }
			}
			fields {
                slug
			}
			html
		}
	}
`